import React from 'react'
import { ItemTypes } from './Constants'
import { useDrag } from 'react-dnd'
import RecordCard from '../RecordCard/RecordCard'

function RecordSource({data, setSourceRecordId, type}) {
    
    const [{isDragging}, drag] = useDrag(() => ({
        type,
        item: data,
        isDragging(monitor) {
          const item = monitor.getItem()
          return item
        },
        collect: monitor => ({
          isDragging: monitor.isDragging(),
          
          
        }),
        
        
      }))
     
      return(
        <div
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                fontSize: 25,
                fontWeight: 'bold',
                cursor: 'move',
                
                
                
            }}
            >
            {data &&
            <div  ref={drag} key={data[0]} className="editRecordSource"
              style={{
                opacity: isDragging ? 0.5 : 1,
                fontSize: 25,
                fontWeight: 'bold',
                cursor: 'move',
              
              
          }}
            >
                <RecordCard 
                    width="210px"
                    height="210px"
                    genre={data[4]}
                    recordData={data}
                    fontSize="13px"
                    smallFont="0.7vw"
                    disableClick={true}
                    recordFontSize="12px"
                />
            </div>
        }
        </div>
      )
}

export default RecordSource