import axios from "axios";

const { REACT_APP_API_ENDPOINT_DEV, REACT_APP_API_ENDPOINT_PROD } = process.env;

console.log("HERE")
console.log(process.env)
console.log(REACT_APP_API_ENDPOINT_DEV)
console.log(process.env.REACT_APP_API_ENDPOINT_DEV)

export const makeRequest = axios.create({
    
    baseURL: REACT_APP_API_ENDPOINT_PROD,
    
    //baseURL: "http://localhost:8800/backend/"
    //withCredentials: true
});