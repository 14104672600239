import { useMutation, useQueryClient } from "@tanstack/react-query"
import { convertFromRaw, EditorState } from "draft-js"
import { useEffect } from "react"
import { useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { makeRequest } from "../../axios"
import Navbar from "../../components/Navbar/Navbar"
import TextEditor from "../../components/texteditor/TextEditor"
import { AuthContext } from "../../context/authContext"
import "./editreview.css"
import Dropdown from "../../components/Dropdown/Dropdown"

import DownArrow from "../../assets/icons/nav-arrow-down-black.svg";

export default function EditReview(props) {

    const navigate = useNavigate();
    const location = useLocation()
    const data = location.state?.data

    console.log(data)

    const {user, logout} = useContext(AuthContext)

    const [file, setFile] = useState(null);
    const [artist, setArtist] = useState("");
    const [recordName, setRecordName] = useState("");
    const [genre, setGenre] = useState("");
    const [releaseYear, setReleaseYear] = useState("");
    const [playTime, setPlayTime] = useState("");
    const [review, setReview] = useState("");
    const [date, setDate] = useState("");
    const [imageChanged, setImageChanged] = useState(false);
    const [subGenre, setSubGenre] = useState("");

    const [genreMenuOpen, setGenreMenuOpen] = useState(false)

    const genreOptions = [
        {value: "BLUES", label: "BLUES"},
        {value: "ROCK", label: "ROCK"},
        {value: "JAZZ", label: "JAZZ"},
        {value: "ANNAT", label: "ANNAT"}
        
    ]

    console.log(genreOptions)

    const handleCallback = (childData) => {
        setReview(JSON.stringify(childData))
    }

    const handleImageChange = (e) => {
        setFile(e.target.files[0])
        setImageChanged(true)
    }

    
    const removeImage = (e) => {
        setFile(null)
        setImageChanged(true)
    }

    const upload = async () => {
        try {
          const formData = new FormData();
          formData.append("file", file);
          const res = await makeRequest.post("https://kavemark.se/api/upload", formData);
          return res.data;
        } catch (err) {
          console.log(err);
        }
    };

    const queryClient = useQueryClient();

    const mutation = useMutation(
        (newPost) => {
          return makeRequest.put("https://kavemark.se/api/records", newPost);
        },
        {
          onSuccess: () => {
            // Invalidate and refetch
            console.log("SUCESS!")
            queryClient.invalidateQueries(["records"]);
          },
        }
    );

    const handleClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let imgUrl = "";
        console.log(file)
        if(!file) console.log("DSFG")
        if (file && imageChanged){ 
            console.log("UPLOADING") 
            imgUrl = await upload();
            console.log(imgUrl)
        }
        if(file && imageChanged){
            mutation.mutate({ id:data.id, artist:artist, recordName:recordName, genre:genre.toLowerCase(), image:imgUrl, review:review, releaseYear:releaseYear, playTime:playTime, reviewedAt:date, reviewedBy:user.username, subGenre:subGenre.toLowerCase()});
        }
        else{
            mutation.mutate({ id:data.id, artist:artist, recordName:recordName, genre:genre.toLowerCase(), review:review, releaseYear:releaseYear, playTime:playTime, reviewedAt:date, reviewedBy:user.username, subGenre:subGenre.toLowerCase()});
        }
        navigate("/")
        return;
      };

      function handleSetFile(e){
        setFile(e.target.files[0])
        setImageChanged(true)
      }

      useEffect(() => {
        setFile(data.image)
        setArtist(data.artist)
        setRecordName(data.recordName)
        setGenre(data.genre)
        setReleaseYear(data.releaseYear)
        setPlayTime(data.playTime)
        setReview(data.review)
        setDate(data.reviewedAt)
        setSubGenre(data.subGenre)
    }, [])

    let editorState = EditorState.createEmpty()

    if(data && data.review != ""){
        const contentState = convertFromRaw(JSON.parse(data.review))
        editorState = EditorState.createWithContent(contentState)
    }

    return(
        <div className="add">
            <Navbar />

            
            <div className="addForm">
                <div className="addFormTop">

                    <p className="addFormHeader">Redigera recension</p>

                    <p className="addFormFieldDesc">Artist/Grupp</p>
                    <input className="largeInput" value={artist} type="text" placeholder="Skriv din text här..." onChange={(e) => setArtist(e.target.value)} name="artist" />

                    <p className="addFormFieldDesc">Album</p>
                    <input className="largeInput" value={recordName} type="text" placeholder="Skriv din text här..." onChange={(e) => setRecordName(e.target.value)} name="recordName" />

                    <div className="addFormTopHorizontal">
                        <div className="addFormLeftVertical">

                        <p>Genre</p>
                        <div className="addFormDropdown">
                            <Dropdown 
                                width='180px'
                                height='55px'
                                iconSize='0.833vw'
                                setOpen={setGenreMenuOpen}
                                open={genreMenuOpen}
                                /*trigger={<i className="dropdownIcon fa-solid fa-bars"></i>}*/
                                trigger={
                                    <div id={genre} className="addFormDropdownTrigger"> 
                                        
                                        <p className="recordsDropdownSortStateDisplay"> 
                                            {genre == "annat" ? "FLER GENRER" : genre.toLocaleUpperCase()} 
                                        </p>

                                        <img src={DownArrow} className="recordsDropdownSortArrow"/>
                                    </div>
                                }
                                menu={[
                                    <div id="blues" onClick={(e) => setGenre("blues")} >BLUES</div>,
                                    <div id="rock"  onClick={(e) => setGenre("rock")} >ROCK</div>,
                                    <div id="jazz"  onClick={(e) => setGenre("jazz")} >JAZZ</div>,
                                    <div id="annat" onClick={(e) => setGenre("annat")} >FLER GENRER</div>,
                                ]}
                                
                            />
                            
                        </div>



                            
                            {/*<select className="mediumInput" onChange={(e) => setGenre(e.target.value.toLowerCase())}>
                                <option>VÄLJ GENRE</option>
                                {genreOptions.map((option, index) => {
                                    return <option key={index} >
                                        {option}
                                    </option>
                                })}
                                
                            </select>
                            {genre == "annat" ? 
                                <input className="subGenreInput" type="text" placeholder="SubGenre" onChange={(e) => setSubGenre(e.target.value)} name="subGenre" /> 
                                : null
                            }
                            {/*<input className="mediumInput" type="text" placeholder="genre" onChange={(e) => setGenre(e.target.value)} name="genre" />*/}
                            
                        </div>

                        <div className="addFormLeftVertical">
                            <p>Releaseår</p>
                            <input className="smallInput" value={releaseYear} type="text" placeholder="ÅÅÅÅ" onChange={(e) => setReleaseYear(e.target.value)} name="releaseYear" />
                        </div>
                        <div className="addFormLeftVertical">
                            <p>Speltid</p>
                            <input className="smallInput" value={playTime} type="text" placeholder="00:00" onChange={(e) => setPlayTime(e.target.value)} name="playTime" />
                        </div>

                        {/*<div className="addFormLeftVertical">
                            <p>DATUM</p>
                            <input className="smallInput" type="text" placeholder="ÅÅÅÅ-MM-DD" onChange={(e) => setDate(e.target.value)} name="playTime" />
                        </div>*/}
                    </div>

                    {genre == "ANNAT" ? 
                        <>
                            <p style={{marginTop:"20px"}} >Etikett</p>
                            <input className="subGenreInput" type="text" 
                                value={subGenre} 
                                placeholder="Skriv din etikett här..." 
                                onChange={(e) => setSubGenre(e.target.value)} 
                                name="subGenre" 
                            /> 
                        </>
                        : null
                    
                    }
                        
                </div>
                <div className="addFormBottom">
                    <div className="addFormEditor">
                        <TextEditor editorState={editorState} className="addFormReviewInput" parentCallback={handleCallback} />
                        {/*
                        <textarea className="addFormReviewInput" placeholder="Skriv din text här..." onChange={(e) => setReview(e.target.value)} />
                        */}
                        
                    </div>
                    <div className="addFormImage">
                        <div className="addImage">
                            <input type="file" id="file" style={{display:"none"}} onChange={(e) => handleSetFile(e)} name="image" />
                            {file ? 
                                <div className="addImagePreview"> 
                                    
                                    {imageChanged ?
                                        <img className="previewImage" src={URL.createObjectURL(file)} />
                                        : <img className="previewImage" src={"/upload/"+file} />
                                    }
                                    <i className="addCloseImageIcon fa-solid fa-xmark" onClick={removeImage}></i>
                                </div>
                                : <label className="addIconText" htmlFor="file">
                                    <i className="addImageIcon fa-solid fa-plus"></i>
                                    <span>LÄGG TILL BILD</span>
                                    
                                </label>
                            }
                        </div>
                    </div>
                        
                </div>
                <button className="addReviewButton" type={"button"} onClick={handleClick}>Publicera</button>
                
            </div>

            

        </div>
    )
}