import React, { useRef, useState } from 'react'

import { ItemTypes } from './Constants'
import { useDrag, useDrop } from 'react-dnd'
import RecordCard from '../RecordCard/RecordCard'
import { useQuery } from '@tanstack/react-query'
import { makeRequest } from '../../axios'

function RecordTarget({onDrop, lastDroppedItem, accept, moveRecord, index, type}) {
   
    const ref = useRef(null)
    const [moving, setMoving] = useState(false)
    
    const [{ isOver, handlerId }, drop] = useDrop(() =>({
        accept,
        drop: onDrop,
        
        collect: monitor => ({
            isOver: monitor.isOver(),
            handlerId: monitor.getHandlerId(),
        }),
        hover(item, monitor) {
            if (!ref.current) {
                return
              }
              const dragIndex = item.index
              const hoverIndex = index
              
              // Don't replace items with themselves
              if (dragIndex === hoverIndex) {
                return
              }
              // Determine rectangle on screen
              const hoverBoundingRect = ref.current?.getBoundingClientRect()
              // Get vertical middle
              const hoverMiddleX =
                (hoverBoundingRect.left - hoverBoundingRect.right) / 2
              // Determine mouse position
              const clientOffset = monitor.getClientOffset()
              // Get pixels to the top
              const hoverClientX = clientOffset.x - hoverBoundingRect.right
              // Only perform the move when the mouse has crossed half of the items height
              // When dragging downwards, only move when the cursor is below 50%
              // When dragging upwards, only move when the cursor is above 50%
              // Dragging downwards
              if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return
              }
              // Dragging upwards
              if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                return
              }
              // Time to actually perform the action
              moveRecord(dragIndex, hoverIndex, item)
              // Note: we're mutating the monitor item here!
              // Generally it's better to avoid mutations,
              // but it's good here for the sake of performance
              // to avoid expensive index searches.
              item.index = hoverIndex
        }
        
    }))

    const [{ isDragging }, drag] = useDrag({
        type,
        item: () => {
          return {lastDroppedItem, isDragging:isDragging, index}
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      })
      
      const opacity = isDragging ? 0 : 1
      drag(drop(ref))
    

    return(
        <div
            ref={ref}
            style={{
                position: 'relative',
                
            }}
            >

            {lastDroppedItem &&
                <div key={lastDroppedItem[0]} className="editFavoriteCard">
                    <RecordCard 
                        width="200px"
                        height="200px"
                        genre={lastDroppedItem[4]}
                        recordData={lastDroppedItem}
                        fontSize="13px"
                        smallFont="0.7vw"
                        disableClick={true}
                        recordFontSize="12px"
                    />
                </div>
            }

           
           
            {isOver && (
                <div
                    style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    zIndex: 1,
                    opacity: 0.5,
                    backgroundColor: 'yellow',
                    }}
                />
            )}
            
        </div>
    )
}

export default RecordTarget