import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./recordcarousel.css"

import testimg from "../../assets/images/a.jpg"
import { useQuery } from '@tanstack/react-query';
import { makeRequest } from '../../axios';

import { convertFromRaw, Editor, EditorState } from 'draft-js';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';

export default function RecordCarousel() {

    const { isLoading, error, isError, data, status } = useQuery(["favoriteRecords"], () =>
        makeRequest.get("https://kavemark.se/api/favorites", {mode: "cors"}).then((res) => {
            return res.data;
        })
     );

    const [currentSlide, setCurrentSlide] = useState(0)

    function handleChange(currentItem) {
        setCurrentSlide(currentItem)
    }

    function cleanUrlString(input) {
       // Extract the prefix and the rest of the string
        const prefix = input.match(/^(\/\w+\/)/);
        const restOfString = input.replace(/^(\/\w+\/)/, '');

        // Remove unwanted characters from the rest of the string
        const cleanedString = restOfString.replace(/[^a-zA-Z0-9- ]/g, '');

        // Replace white spaces with hyphens
        const sanitizedString = cleanedString.replace(/\s+/g, '-');

        // Combine the prefix and the sanitized string
        const updatedString = prefix ? prefix[0] + sanitizedString : sanitizedString;

        return updatedString;
    }

    return (
        <div className='carouselWrapper'>
            <p className='carouselHeader'>Favoriter</p>

            <MediaQuery maxWidth={600}>
                <Carousel width="300px" showThumbs={false} showStatus={false}  autoPlay infiniteLoop onChange={handleChange}>
                    {isLoading ? "Loading..." 
                    : data.map(favoriteRecord => {
                        return (
                            <div key={favoriteRecord[0]} className='carouselItem'>
                                
                                <div className='mobileSliderImage'>
                                    <img src={"/upload/"+favoriteRecord[3]} />
                                </div>
                        
                        
                            </div>
                        )
                    })}
                </Carousel>
                <div className='mobileSlideDescription'>
                    
                    <p className='mobileBandName'>
                        {isLoading ? "Loading..." : data[currentSlide][1]}
                    </p>
                    <p className='mobileRecordName'>
                        {isLoading ? "Loading..." : data[currentSlide][2]}
                    </p>
                    <div className='mobileSlideShortDesc'>
                    {isLoading ? "Loading..." :
                        EditorState.createWithContent(
                            convertFromRaw(JSON.parse(data[currentSlide][5])))
                            .getCurrentContent().getPlainText('\u0001')
                    }
                    </div>

                    
                    
                </div>
                {isLoading ? "Loading..." :
                <div className="mobileSliderReadMoreContainer">
                    <Link className="mobileSliderReadMoreLink"
                        to={cleanUrlString("/"+data[currentSlide][4]+"/"+data[currentSlide][1]+" "+data[currentSlide][2])}
                        state={{
                            data: {
                                id:data[currentSlide][0],
                                artist:data[currentSlide][1],
                                recordName:data[currentSlide][2],
                                review:data[currentSlide][5], 
                                releaseYear: data[currentSlide][6],
                                image: data[currentSlide][3],
                                genre: data[currentSlide][4],
                                playTime: data[currentSlide][7],
                                reviewedAt: data[currentSlide][8],
                                reviewedBy: data[currentSlide][9],
                                subGenre: data[currentSlide][11],
                                
                            }
                        }}
                    >
                    <div className="mobileSliderReadMore">Läs mer</div>
                    </Link>
                </div>
                }
            </MediaQuery>

            <MediaQuery minWidth={601} maxWidth={1000}> {/* TABLET */}
                <Carousel width="650px" showThumbs={false} showStatus={false}  autoPlay infiniteLoop onChange={handleChange}>

                    {isLoading ? "Loading..." 
                    : data.map(favoriteRecord => {
                        return (
                            <div key={favoriteRecord[0]} className='carouselItem'>
                                <div className='slideLeft'>
                                <p className='slideLeftBand'>{favoriteRecord[1]}</p>
                                <p className='slideLeftRecord'>{favoriteRecord[2]}</p>

                                <p className='slideLeftShortDesc'>
                                    
                                    {
                                        EditorState.createWithContent(
                                            convertFromRaw(JSON.parse(favoriteRecord[5])))
                                            .getCurrentContent().getPlainText('\u0001')
                                    }
                                </p>

                                <Link className="sliderReadMoreLink"
                                    to={cleanUrlString("/"+data[currentSlide][4]+"/"+data[currentSlide][1]+" "+data[currentSlide][2])}
                                    state={{
                                        data: {
                                            id:data[currentSlide][0],
                                            artist:data[currentSlide][1],
                                            recordName:data[currentSlide][2],
                                            review:data[currentSlide][5], 
                                            releaseYear: data[currentSlide][6],
                                            image: data[currentSlide][3],
                                            genre: data[currentSlide][4],
                                            playTime: data[currentSlide][7],
                                            reviewedAt: data[currentSlide][8],
                                            reviewedBy: data[currentSlide][9],
                                            subGenre: data[currentSlide][11],
                                            
                                        }
                                    }}
                                >
                                    <div className='slideLeftButton'>Läs mer</div>
                                </Link>
                            </div>
                            <div className='slideRight'>
                                <img src={"/upload/"+favoriteRecord[3]} />
                            </div>
                        
                        
                        </div>
                        )
                    })}
                </Carousel>
            </MediaQuery>

            <MediaQuery minWidth={1001} maxWidth={1599}>
            <div style={{height:"570px", marginTop:"60px"}}>
                <Carousel width="1010px" showThumbs={false} showStatus={false}  infiniteLoop autoPlay onChange={handleChange}>

                    {isLoading ? "Loading..." 
                    : data.map(favoriteRecord => {
                        return (
                            <div key={favoriteRecord[0]} className='carouselItem'>
                                <div className='slideLeft'>
                                <p className='slideLeftBand'>{favoriteRecord[1]}</p>
                                <p className='slideLeftRecord'>{favoriteRecord[2]}</p>

                                <p className='slideLeftShortDesc'>
                                    
                                    {
                                        EditorState.createWithContent(
                                            convertFromRaw(JSON.parse(favoriteRecord[5])))
                                            .getCurrentContent().getPlainText('\u0001')
                                    }
                                </p>
                                
                                <Link className="sliderReadMoreLink"
                                    to={cleanUrlString("/"+data[currentSlide][4]+"/"+data[currentSlide][1]+" "+data[currentSlide][2])}
                                    state={{
                                        data: {
                                            id:data[currentSlide][0],
                                            artist:data[currentSlide][1],
                                            recordName:data[currentSlide][2],
                                            review:data[currentSlide][5], 
                                            releaseYear: data[currentSlide][6],
                                            image: data[currentSlide][3],
                                            genre: data[currentSlide][4],
                                            playTime: data[currentSlide][7],
                                            reviewedAt: data[currentSlide][8],
                                            reviewedBy: data[currentSlide][9],
                                            subGenre: data[currentSlide][11],
                                            
                                        }
                                    }}
                                >
                                    <div className='slideLeftButton'>Läs mer</div>
                                </Link>
                            </div>
                            <div className='slideRight'>
                                <img src={"/upload/"+favoriteRecord[3]} />
                            </div>
                        
                        
                        </div>
                        )
                    })}
                </Carousel>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={1600}>
                <div style={{height:"740px", marginTop:"60px"}}>
                <Carousel width="1500px" showThumbs={false} showStatus={false}  infiniteLoop autoPlay onChange={handleChange}>

                    {isLoading ? "Loading..." 
                    : data.map(favoriteRecord => {
                        return (
                            <div key={favoriteRecord[0]} className='carouselItem'>
                                <div className='slideLeft'>
                                <p className='slideLeftBand'>{favoriteRecord[1]}</p>
                                <p className='slideLeftRecord'>{favoriteRecord[2]}</p>

                                <p className='slideLeftShortDesc'>
                                    
                                    {
                                        EditorState.createWithContent(
                                            convertFromRaw(JSON.parse(favoriteRecord[5])))
                                            .getCurrentContent().getPlainText('\u0001')
                                    }
                                </p>
                                
                                <Link className="sliderReadMoreLink"
                                    to={cleanUrlString("/"+data[currentSlide][4]+"/"+data[currentSlide][1]+" "+data[currentSlide][2])}
                                    state={{
                                        data: {
                                            id:data[currentSlide][0],
                                            artist:data[currentSlide][1],
                                            recordName:data[currentSlide][2],
                                            review:data[currentSlide][5], 
                                            releaseYear: data[currentSlide][6],
                                            image: data[currentSlide][3],
                                            genre: data[currentSlide][4],
                                            playTime: data[currentSlide][7],
                                            reviewedAt: data[currentSlide][8],
                                            reviewedBy: data[currentSlide][9],
                                            subGenre: data[currentSlide][11],
                                            
                                        }
                                    }}
                                >
                                    <div className='slideLeftButton'>Läs mer</div>
                                </Link>
                            </div>
                            <div className='slideRight'>
                                <img src={"/upload/"+favoriteRecord[3]} />
                            </div>
                        
                        
                        </div>
                        )
                    })}
                </Carousel>
                </div>
            </MediaQuery>
        </div>
    )
}