import "./header.css"
import header from "../../assets/images/header.png"
import { Link } from "react-router-dom"
import { useContext } from "react"
import { AuthContext } from "../../context/authContext"
import headerbg from "../../assets/images/headerbg.jpg"
import headerbg2 from "../../assets/images/headerbg2.jpg"
import headerbg_org from "../../assets/images/headerbg_org2.jpg"
import Navbar from "../Navbar/Navbar"
import MediaQuery from "react-responsive"

import header_background_mobile from "../../assets/images/blockazz_startsida_bild_360x815.png"
import header_background_tablet from "../../assets/images/blockazz_startsida_bild_768x1025.png"
import header_background_web_small from "../../assets/images/blockazz_startsida_bild_1600x900.png"
import header_background from "../../assets/images/blockazz_startsida_bild_1920x1080.png"

import header_360 from "../../assets/images/header_360x800.png"
import header_375 from "../../assets/images/header_375x812.png"
import header_414 from "../../assets/images/header_414x896.png"
import header_768 from "../../assets/images/header_768x1025.png"
import header_1280 from "../../assets/images/header_1280x800.png"
import header_1366 from "../../assets/images/header_1366x768.png"
import header_1600 from "../../assets/images/header_1600x900.png"
import header_1920 from "../../assets/images/header_1920x1080.png"

export default function Header( screenWidth ) {
    const {user, logout} = useContext(AuthContext)

    return (
        
        <div className="header">
            <MediaQuery minWidth={300} maxWidth={370}>
                <img 
                    src={header_360}
                />
            </MediaQuery>

            <MediaQuery minWidth={371} maxWidth={400}>
                <img 
                    src={header_375}
                />
            </MediaQuery>

            <MediaQuery minWidth={401} maxWidth={660}>
                <img 
                    src={header_414}
                />
            </MediaQuery>

            <MediaQuery minWidth={661} maxWidth={980}>
                <img 
                    src={header_768}
                />
            </MediaQuery>

            <MediaQuery minWidth={981} maxWidth={1320}>
                <img 
                    src={header_1280}
                />
            </MediaQuery>

            <MediaQuery minWidth={1321} maxWidth={1450}> {/* SMALL WEB */}
                <img 
                    src={header_1366}
                />
            </MediaQuery>

            <MediaQuery minWidth={1451} maxWidth={1700}>
                <img 
                    src={header_1600}
                />
            </MediaQuery>

            <MediaQuery minWidth={1701}>
                <img 
                    src={header_1920}
                />
            </MediaQuery>

            <Navbar />
             
            
            
            

            
            
        </div>
    )
}