import { useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar"
import RecordCard from "../../components/RecordCard/RecordCard";
import "./searchresult.css"
import MediaQuery from "react-responsive";
import MobileSortMenu from "../../components/MobileSpecific/MobileSortMenu/MobileSortMenu";
import { useEffect, useMemo, useState } from "react";
import Footer from "../../components/footer/Footer";
import Pagination from "../../components/pagination/Pagination";
import Dropdown from "../../components/Dropdown/Dropdown";

import SortIcon from "../../assets/icons/menu-scale-black.svg";
import DownArrow from "../../assets/icons/nav-arrow-down-black.svg";

let PageSize = 30;

export default function SearchResult() {

    const location = useLocation()
    const data = location.state?.data

    const [currentPage, setCurrentPage] = useState(1);
    const [sortState, setSortState] = useState("");
    const [sortMenuOpen, setSortMenuOpen] = useState(false)
    const [sortStateDisplayString, setSortStateDisplayString] = useState("");
    const [recordData, setRecordData] = useState([])

   

    const handleArtistAscending = () => {
        const sortAsc = [...recordData]
        sortAsc.sort((a, b) => {
            return a[1] > b[1] ? -1 : 1;
        })
        setRecordData(sortAsc)
        setSortState("artistAsc")
        setSortStateDisplayString("Artist A-Ö")
        setCurrentPage(1)
    }

    const handleArtistDescending = () => {
        const sortDesc = [...recordData]
        sortDesc.sort((a, b) => {
            return a[1] > b[1] ? 1 : -1;
        })
        setRecordData(sortDesc)
        setSortStateDisplayString("Artist Ö-A")
        setSortState("artistDesc")
        setCurrentPage(1)
    }

    const handleRecordAscending = () => {
        
        const sortAsc = [...recordData]
        sortAsc.sort((a, b) => {
            return a[2] > b[2] ? -1 : 1;
        })
        setRecordData(sortAsc)
        setSortState("recordAsc")
        setSortStateDisplayString("Skiva A-Ö")
        setCurrentPage(1)
    }

    const handleRecordDescending = () => {
        
        const sortAsc = [...recordData]
        sortAsc.sort((a, b) => {
            return a[2] > b[2] ? 1 : -1;
        })
        setRecordData(sortAsc)
        setSortState("recordDesc")
        setSortStateDisplayString("Skiva Ö-A")
        setCurrentPage(1)
    }

    useEffect(() =>{
        setRecordData(data.records.reverse())
        setSortState("latest")
        setSortStateDisplayString("Senaste recension")
        
        if(sortState === "latest"){
            handleLatest()
        }
        else if(sortState === "artistDesc"){
            handleArtistDescending()
        }
        else if(sortState === "artistAsc"){
            handleArtistAscending()
        }
        else if(sortState === "recordDesc"){
            handleRecordDescending()
        }
        else if(sortState === "recordAsc"){
            handleRecordAscending()
        }
    }, [data, sortState])

    const currentPageData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return (recordData) ? recordData.slice(firstPageIndex, lastPageIndex) : "loading...";
    }, [currentPage, recordData]);

    const handleLatest = () => {
        const sortLatest = [...recordData]
        sortLatest.sort((a, b) => {
            return a[0] > b[0] ? -1 : 1
        })
        setRecordData(sortLatest)
        setSortState("latest")
        setSortStateDisplayString("Senaste recension")
        setCurrentPage(1)
    }

    return(
        <div className="searchResult">
            <Navbar />

            <MediaQuery maxWidth={600}>
                <div className="recordsTopInfoTop">
                    <div className="recordsSearchText">{"Du sökte på: " + data.searchField}</div>
                    <p className="recordsTotal">{data.records.length} recensioner</p>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={601} maxWidth={1000}> {/* Both mobile and tablet */}
                <div className="recordsTopInfoTop">
                    <div className="recordsSearchText">{"Du sökte på: " + data.searchField}</div>
                    <p className="recordsTotal">{data.records.length} recensioner</p>
                </div>
            </MediaQuery>
            <div className="recordsTopInfo">
                <MediaQuery maxWidth={1000}> {/* Both mobile and tablet */}
                    <div className="recordsTopInfoLeft">
                        
                        <div className="navBarHamburgerMenu">
                            <MobileSortMenu  
                                width='9.4vw'
                                height='30px'
                                iconSize='20px'
                                marginLeft="0"
                                setOpen={setSortMenuOpen}
                                open={sortMenuOpen}
                                setSortState={setSortState}
                                trigger={
                                    <div className="mobileSortTrigger">
                                        {/*<i className="mobileSortIcon fa-solid fa-bars"></i>*/}
                                        <img src={SortIcon} className="mobileSortIcon" />
                                        <p>Sortera</p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    
                </MediaQuery>

                <MediaQuery minWidth={1001} maxWidth={1599}>
                    <div className="recordsSearchText">{"Du sökte på: " + data.searchField}</div>
                    <p className="recordsTotal">{data.records.length} recensioner</p>
                        <div className="recordsDropdown">
                            <Dropdown 
                                width='245px'
                                height='30px'
                                iconSize='0.833vw'
                                setOpen={setSortMenuOpen}
                                open={sortMenuOpen}
                                /*trigger={<i className="dropdownIcon fa-solid fa-bars"></i>}*/
                                trigger={
                                    <div id={sortState} className="recordsDropdownSortTrigger"> 
                                        <img src={SortIcon} className="dropdownSortIcon" />

                                        <p className="recordsDropdownSortStateDisplay"> 
                                            {sortStateDisplayString} 
                                        </p>

                                        <img src={DownArrow} className="recordsDropdownSortArrow"/>
                                    </div>
                                }
                                menu={[
                                    <div id="latest" onClick={handleLatest}>Senaste recension</div>,
                                    <div id="artistAsc" onClick={handleArtistAscending}>Artist A-Ö</div>,
                                    <div id="artistDesc" onClick={handleArtistDescending}>Artist Ö-A</div>,
                                    <div id="recordAsc" onClick={handleRecordAscending}>Skiva A-Ö</div>,
                                    <div id="recordDesc" onClick={handleRecordDescending}>Skiva Ö-A</div>
                                ]}
                                
                            />
                            
                    </div>
                    
                </MediaQuery>

                <MediaQuery minWidth={1600}>
                    <div className="recordsSearchText">{"Du sökte på: " + data.searchField}</div>
                    <p className="recordsTotal">{data.records.length} recensioner</p>
                    <div className="recordsDropdown">
                            <Dropdown 
                                width='245px'
                                height='30px'
                                iconSize='0.833vw'
                                setOpen={setSortMenuOpen}
                                open={sortMenuOpen}
                                /*trigger={<i className="dropdownIcon fa-solid fa-bars"></i>}*/
                                trigger={
                                    <div id={sortState} className="recordsDropdownSortTrigger"> 
                                        <img src={SortIcon} className="dropdownSortIcon" />

                                        <p className="recordsDropdownSortStateDisplay"> 
                                            {sortStateDisplayString} 
                                        </p>

                                        <img src={DownArrow} className="recordsDropdownSortArrow"/>
                                    </div>
                                }
                                menu={[
                                    <div id="latest" onClick={handleLatest}>Senaste recension</div>,
                                    <div id="artistAsc" onClick={handleArtistAscending}>Artist A-Ö</div>,
                                    <div id="artistDesc" onClick={handleArtistDescending}>Artist Ö-A</div>,
                                    <div id="recordAsc" onClick={handleRecordAscending}>Skiva A-Ö</div>,
                                    <div id="recordDesc" onClick={handleRecordDescending}>Skiva Ö-A</div>
                                ]}
                                
                            />
                            
                        </div>
                        

                </MediaQuery>
            </div>

            <div className="recordSection">
                <MediaQuery maxWidth={600}>
                    {currentPageData.map(item => {
                        return (
                            <div key={item[0]} className="recordSectionCard">
                                <RecordCard 
                                    width="150px"
                                    height="150px"
                                    genre={item[4]}
                                    recordData={item}
                                    showLabels={true}
                                    fontSize="12px"
                                    smallFont="0.6em"
                                    recordFontSize="10px"

                                />
                            </div>
                            
                        );
                    })
                    }
                </MediaQuery>

                <MediaQuery minWidth={601} maxWidth={1000}> {/* TABLET */}
                    {currentPageData.map(item => {
                        return (
                            <div key={item[0]} className="recordSectionCard">
                                <RecordCard 
                                    width="315px"
                                    height="315px"
                                    genre={item[4]}
                                    recordData={item}
                                    showLabels={true}
                                    fontSize="22px"
                                    smallFont="0.6em"
                                    recordFontSize="18px"

                                />
                            </div>
                            
                        );
                    })
                    }
                </MediaQuery>

                <MediaQuery minWidth={1001} maxWidth={1599}>
                    {currentPageData.map((item, index) => {
                        const numItemsInRow = Math.min(data.records.length - Math.floor(index / 5) * 5, 5);
                        const isLastRow = Math.floor(index / 5) === Math.floor((data.records.length - 1) / 5);
                        let marginRight = '0';
    
                        if (isLastRow && numItemsInRow < 5 && index === data.records.length - 1) {
                            const positionInRow = index % 5 + 1; // Position of the current item in the row (1-indexed)
                        
                            if(numItemsInRow == 2){
                                marginRight = 622.5
                            }
                            else if(numItemsInRow == 3){
                                marginRight = 415
                            }
                            else if(numItemsInRow == 4){
                                marginRight = 207.5
                            }
                        }
                            return (
                                <div key={item[0]} className="recordSectionCard" style={{marginRight}}>
                                    <RecordCard 
                                        width="180px"
                                        height="180px"
                                        genre={item[4]}
                                        recordData={item}
                                        showLabels={true}
                                        fontSize="14px"
                                        smallFont="0.6em"
                                        recordFontSize="11px"
                                    />
                                </div>
                                
                            );
                        })
                    }
                </MediaQuery>

                <MediaQuery minWidth={1600}>
                    {currentPageData.map((item, index) => {
                        const numItemsInRow = Math.min(data.records.length - Math.floor(index / 5) * 5, 5);
                        const isLastRow = Math.floor(index / 5) === Math.floor((data.records.length - 1) / 5);
                        let marginRight = '0';
    
                        if (isLastRow && numItemsInRow < 5 && index === data.records.length - 1) {
                            const positionInRow = index % 5 + 1; // Position of the current item in the row (1-indexed)
                        
                            if(numItemsInRow == 2){
                                marginRight = 1005
                            }
                            else if(numItemsInRow == 3){
                                marginRight = 670
                            }
                            else if(numItemsInRow == 4){
                                marginRight = 335
                            }
                        }
                            return (
                                <div key={item[0]} className="recordSectionCard" style={{marginRight}}>
                                    <RecordCard 
                                        width="300px"
                                        height="300px"
                                        genre={item[4]}
                                        recordData={item}
                                        showLabels={true}

                                    />
                                </div>
                                
                            );
                        })
                    }
                </MediaQuery>

            </div>

            <div className="recordsPagination">
                <Pagination  className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={recordData.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            </div>

            <Footer />
        </div>
        
    )
}