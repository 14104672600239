
import Topbar from "../../components/topbar/Topbar"
import Record from "../../components/record/Record"
import Records from "../../components/records/Records"
import Footer from "../../components/footer/Footer"
import LatestSection from "../../components/latestsection/LatestSection"
import RecordCarousel from "../../components/carousel/RecordCarousel"
import AboutSection from "../../components/aboutsection/AboutSection"
import Navbar from "../../components/Navbar/Navbar"
import Header from "../../components/header/Header"
import "./home.css"
import { useQuery } from "@tanstack/react-query"
import { makeRequest } from "../../axios"
import { useEffect, useState } from "react"
import LatestRecords from "../../components/LatestRecords/LatestRecords"
import MediaQuery from "react-responsive"
import MobileWelcome from "../../components/MobileSpecific/MobileWelcome/MobileWelcome"

export default function Home() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
  }, []);

  /*const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange(){
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])*/

  const bluesQuery = useQuery(["blues_records"], () =>
    makeRequest.get("https://kavemark.se/api/records", {mode: "cors", params: {genre: "blues"}}).then((res) => {
      
      return res.data;
    })
  );

  const dataArr = bluesQuery.data?.map((data) => data)



    return (
        <div className="home">
            {/* <Navbar data={dataArr} /> This is now in header, testing this*/}
            <Header /*screenWidth={width}*/ />
            <MediaQuery maxWidth={600}>
              <MobileWelcome />
            </MediaQuery>
            <MediaQuery minWidth={601} maxWidth={1000}> {/* TABLET */}
              <MobileWelcome />
            </MediaQuery>
            <MediaQuery minWidth={1001} maxWidth={1599}> {/* TABLET */}
              <MobileWelcome />
            </MediaQuery>
            <MediaQuery minWidth={1600}>
              <MobileWelcome />
            </MediaQuery>
            {/* <Topbar /> */}
            <LatestRecords />
            <RecordCarousel id="recordCarousel" />
            <AboutSection detailed={true} />
            <Footer />
        </div>
    )
}