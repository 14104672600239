import { useQueries, useQuery } from "@tanstack/react-query"
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { makeRequest } from "../../axios";
import "./mobilesearchbar.css"

import SearchIcon from "../../assets/icons/search-gold.svg";
import SearchIconBlack from "../../assets/icons/search-black.svg";
import MediaQuery from "react-responsive";

export default function MobileSearchBar(props){

    const [searchField, setSearchField] = useState("")
    const inputRef = useRef(null)
    const navigate = useNavigate();
    const [animationEnded, setAnimationEnded] = useState(false)

    const bluesQuery = useQuery(["blues_records"], () =>
        makeRequest.get("https://kavemark.se/api/records", {mode: "cors", params: {genre: "blues"}}).then((res) => {
            return res.data;
        })
    );

    const rockQuery = useQuery(["rock_records"], () =>
        makeRequest.get("https://kavemark.se/api/records", {mode: "cors", params: {genre: "rock"}}).then((res) => {
            return res.data;
        })
    );

    const jazzQuery = useQuery(["jazz_records"], () =>
        makeRequest.get("https://kavemark.se/api/records", {mode: "cors", params: {genre: "jazz"}}).then((res) => {
            return res.data;
        })
    );

    const otherQuery = useQuery(["other_records"], () =>
        makeRequest.get("https://kavemark.se/api/records", {mode: "cors", params: {genre: "annat"}}).then((res) => {
            return res.data;
        })
    );

    /*const bluesQuery = useQuery(["blues_records"])
    const rockQuery = useQuery(["rock_records"])
    const jazzQuery = useQuery(["jazz_records"])
    const otherQuery = useQuery(["other_records"])
*/
    const bluesArr = bluesQuery.data?.map((data) => data)
    const rockArr = rockQuery.data?.map((data) => data)
    const jazzArr = jazzQuery.data?.map((data) => data)
    const otherArr = otherQuery.data?.map((data) => data)

    let allArr;
    if(bluesArr && rockArr && jazzArr && otherArr) {
        allArr = [...bluesArr, ...rockArr, ...jazzArr, ...otherArr]
    }

    if(animationEnded){
        inputRef.current?.focus()
    }

    //const dataArr = result.data?.map((data) => data)
    if(!allArr)
        return

    
    

    const filteredArr = allArr.filter(
        record => {
            
            return(
                record[1].toLowerCase().includes(searchField.toLowerCase()) ||
                record[2].toLowerCase().includes(searchField.toLowerCase())
            )
        }
    )

    const handleEnter = (e) => {
        if(e.key == 'Enter'){
            props.setSearchResult(filteredArr)
            props.setSearchField(searchField)
            inputRef.current.value = "" // Clear input after searching
            //navigate("/search", {state:{data:{records:filteredArr, searchField:searchField}}})
        }
    }

    function handleClick()  {
        
        props.setSearchResult(filteredArr)
        props.setSearchField(searchField)
        inputRef.current.value = "" // Clear input after searching
        //navigate("/search", {state:{data:{records:filteredArr, searchField:searchField}}})
        
    }

    return(
        <>
        <MediaQuery maxWidth={1000}>
            <div className="mobileSearchBar"
            style={{
                position: 'fixed', // Position fixed to keep it relative to the viewport
                top: 0,
                right: props.show ? 0 : '-100%', // Slide in from right if shown
                bottom: 0,
                width: '100vw', // Set width to match viewport width
                zIndex:10,
                opacity: props.show ? 1 : 0, // Opacity transition
                transform: `translateX(${props.show ? '0%' : '100%'})`, // Slide in from right transition
                transition: "opacity 1s, transform 1s, right 1s", // Specify transitions for opacity, transform, and right
            }}
            onTransitionEnd={() => props.show ? inputRef.current.focus() : ""}
            
            >
                <input id="searchbarid"
                    className="mobileSearchBarInput" 
                    type="text" 
                    placeholder="Sök efter artist eller album" 
                    ref={inputRef}
                
                    onKeyDown={handleEnter} onChange={(e) => setSearchField(e.target.value)} />
                <div className="mobileSearchBarLink" onClick={handleClick}>
                    
                    <img src={SearchIcon} className="navbarSearchIcon" />
                </div>
            </div>
        </MediaQuery>

        <MediaQuery minWidth={1001} maxWidth={1599}>
            <div className="mobileSearchBar"
                style={{
                    
                    zIndex:10,
                    left: props.show ? "calc(50% + 120px)" : "110%",
                    top: "50px",
                    transition: props.show ? "left 1s" : null
                }}
                onTransitionEnd={() => props.show ? inputRef.current.focus() : ""}
            >
                <input id="searchbarid"
                    className="mobileSearchBarInput" 
                    type="text" 
                    placeholder="Sök efter artist eller album" 
                    ref={inputRef}
                    onKeyDown={handleEnter} onChange={(e) => setSearchField(e.target.value)} />

                <div className="mobileSearchBarLink" onClick={handleClick}>
                    <img src={SearchIconBlack} className="navbarSearchIcon" />
                </div>
            </div>
        </MediaQuery>
        </>
    )
}