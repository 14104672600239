import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import RecordCard from "../RecordCard/RecordCard";
import "./latestsection.css"
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

export default function LatestSection({genreOne, genreTwo}) {

    const { isLoading, data } = useQuery([genreOne+genreTwo+"latestRecords"], () =>
    makeRequest.get("https://kavemark.se/api/getlatestrecords", {mode: "cors", params: {genreOne: genreOne, genreTwo: genreTwo}}).then((res) => {
        return res.data;
    }));

    return (
        <div className="latestSection">
            <div className="latestSectionLeft">
                <p className="latestSectionHeader">{genreOne.toUpperCase()}</p>

                <MediaQuery maxWidth={600}>
                    <div className="latestRecords">
                        {isLoading ? "Loading..." : data[0].map((recordData, index) => (
                            // Check if the index is less than 2 to render only the first two items
                            index < 2 && (
                            <div key={recordData[0]} className="latestSectionCard">
                                <RecordCard
                                width="240px"
                                height="240px"
                                genre={'BLUES'}
                                recordData={recordData}
                                fontSize="18px"
                                smallFont="0.6em"
                                recordFontSize="14px"
                                />
                            </div>
                            )
                        ))}
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={601} maxWidth={1000}> {/* TABLET */}
                    <div className="latestRecords">
                        {isLoading ? "Loading..." : data[0].map((recordData, index) => (
                            // Check if the index is less than 2 to render only the first two items
                            
                            <div key={recordData[0]} className="latestSectionCard">
                                <RecordCard
                                width="240px"
                                height="240px"
                                genre={'BLUES'}
                                recordData={recordData}
                                fontSize="18px"
                                smallFont="0.6em"
                                recordFontSize="14px"
                                />
                            </div>
                            
                        ))}
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={1001} maxWidth={1599}>{/* SMALL WEB */}
                    <div className="latestRecordsCards">
                        {isLoading ? "Loading..."
                        : data[0].map(recordData =>{
                            return (
                                <div key={recordData[0]} className="latestSectionCard">
                                    <RecordCard 
                                        width="180px"
                                        height="180px"
                                        genre={'BLUES'}
                                        recordData={recordData}
                                        fontSize="15px"
                                        smallFont="0.6em"
                                        recordFontSize="11px"
                                    />
                                </div>
                            )
                        })}
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={1600}>
                    <div className="latestRecordsCards">
                        {isLoading ? "Loading..."
                        : data[0].map(recordData =>{
                            return (
                                <div key={recordData[0]} className="latestSectionCard">
                                    <RecordCard 
                                        width="315px"
                                        height="315px"
                                        genre={'BLUES'}
                                        recordData={recordData}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </MediaQuery>
                <Link className="latestMoreButton" to={"/"+genreOne.toLowerCase()}>
                    <div >
                        Fler {genreOne.toLowerCase()}recensioner
                    </div>
                </Link>
            </div>

            <div className="latestSectionRight">
                <p className="latestSectionHeader">
                {genreTwo.toLowerCase() === "annat" ?
                        "FLER GENRER"
                        : genreTwo.toUpperCase()}
                </p>

                <MediaQuery maxWidth={600}>
                    <div className="latestRecords">
                        {isLoading ? "Loading..." : data[1].map((recordData, index) => (
                            // Check if the index is less than 2 to render only the first two items
                            index < 2 && (
                            <div key={recordData[2]} className="latestSectionCard">
                                <RecordCard
                                width="240px"
                                height="240px"
                                genre={'BLUES'}
                                recordData={recordData}
                                fontSize="18px"
                                smallFont="0.6em"
                                recordFontSize="14px"
                                />
                            </div>
                            )
                        ))}
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={601} maxWidth={1000}> {/* TABLET */}
                    <div className="latestRecords">
                        {isLoading ? "Loading..." : data[1].map((recordData, index) => (
                            <div key={recordData[2]} className="latestSectionCard">
                                <RecordCard
                                width="240px"
                                height="240px"
                                genre={'BLUES'}
                                recordData={recordData}
                                fontSize="18px"
                                smallFont="0.6em"
                                recordFontSize="14px"
                                />
                            </div>
                            
                        ))}
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={1001} maxWidth={1599}>{/* SMALL WEB */}
                    <div className="latestRecordsCards">
                        {isLoading ? "Loading..."
                        : data[1].map(recordData =>{
                            return (
                                <div key={recordData[2]} className="latestSectionCard">
                                    <RecordCard 
                                        width="180px"
                                        height="180px"
                                        genre={'BLUES'}
                                        recordData={recordData}
                                        fontSize="15px"
                                        smallFont="0.6em"
                                        recordFontSize="11px"
                                    />
                                </div>
                            )
                        })}
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={1600}>
                    <div className="latestRecordsCards">
                        {isLoading ? "Loading..."
                        : data[1].map(recordData =>{
                            return (
                                <div key={recordData[0]} className="latestSectionCard">
                                    <RecordCard 
                                        width="315px"
                                        height="315px"
                                        genre={'ROCK'}
                                        recordData={recordData}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </MediaQuery>


                <Link className="latestMoreButton" to={"/"+genreTwo.toLowerCase()}>
                    <div >
                        {genreTwo.toLowerCase() === "annat" ?
                        "Utforska fler genrer"
                        : "Fler " + genreTwo.toLowerCase() + "recensioner"}
                    </div>
                </Link>
            </div>




            {/*<div className="latestLists">
                {isLoading ? "Loading..." :
                    <Latest 
                        genre={'BLUES'}
                        records={data[0]}
                    />
                }
                {isLoading ? "Loading..." :
                    <Latest 
                        genre={'ROCK'} 
                        records={data[1]}
                    
                    />
                }
                {/* TODO: Add jazz and other */}
            {/*</div>*/}
        </div>
    )
}