import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import Topbar from "../../../components/topbar/Topbar";
import "./login.css"
import Navbar from "../../../components/Navbar/Navbar";
import header from "../../../assets/images/header.png"

import logo from "../../../assets/images/logo.png"

const Login = () => {

    const navigate = useNavigate();
    const {loginUser, wait, loggedInCheck} = useContext(AuthContext);
    const [redirect, setRedirect] = useState(false);
    const [inputs, setInputs] = useState({
        email: "",
        password: "",
    });

    const [err, setErr] = useState(null)

    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const {login, logout} = useContext(AuthContext);

    const handleLogin = async (e) => {
        e.preventDefault();
       
        const data = await loginUser(inputs)
        if(data.success){
            //e.target.reset()
            setRedirect('Redirecting')
            await loggedInCheck()
            navigate("/");
            return;
        }
        else{
            setErr("Fel email eller lösenord. Försök igen!")
        }
        console.log(data.message)
        
    }

    return (
        <div className="login">
            <div className="loginWrapper">
                <img src={logo} />
                <div className="loginInnerWrapper">
                    <p className="loginHeader">Logga in</p>
                    <div className="loginForm">

                        <p className="loginFieldDesc">Email</p>
                        <input type="text" placeholder="Skriv din email här..." onChange={handleChange} name="email" />

                        <p className="loginFieldDesc" style={{marginTop:"28px"}} >Lösenord</p>
                        <input type="password" placeholder="Skriv ditt lösenord här..." onChange={handleChange} name="password" />
                        {err && err}

                        <button style={{marginTop:"60px"}} onClick={handleLogin}>Logga in</button>
                    
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Login;