import "./recordcard.css"
import { Link } from "react-router-dom"

export default function RecordCard( props ) {
    
    const textLength = props.recordData[1].length
    const recordTextLength = props.recordData[2].length
   

    let fontSize = props.fontSize

    let artistCardText =  props.recordData[1]
    let recordCardText =  props.recordData[2]

    /*if(textLength > 20){
        fontSize = "18px";
    }*/
    
    /*if(props.fontSize){
        fontSize = props.fontSize
        if(textLength > 20){
            fontSize = props.smallFont;
        }
    }*/

    if(textLength > 23){
        artistCardText = artistCardText.slice(0, -(artistCardText.length-23)) + "..."
    }

    if(recordTextLength > 30){
        //recordCardText = recordCardText.replace(/.$/,"...")
        recordCardText = recordCardText.slice(0, -(recordCardText.length-30)) + "..."
    }

    /*let search = ' ';
    let replaceWith = '-';
    let artist = props.recordData[1].replaceAll(search, replaceWith);
    let recordName = props.recordData[2].replaceAll(search, replaceWith);
    search = ',';
    replaceWith = '';
    artist = artist.replaceAll(search, replaceWith);
    recordName = recordName.replaceAll(search, replaceWith);
    */

    function cleanUrlString(input) {
        // Extract the prefix and the rest of the string
         const prefix = input.match(/^(\/\w+\/)/);
         const restOfString = input.replace(/^(\/\w+\/)/, '');
 
         // Remove unwanted characters from the rest of the string
         const cleanedString = restOfString.replace(/[^a-zA-Z0-9- ]/g, '');
 
         // Replace white spaces with hyphens
         const sanitizedString = cleanedString.replace(/\s+/g, '-');
 
         // Combine the prefix and the sanitized string
         const updatedString = prefix ? prefix[0] + sanitizedString : sanitizedString;
 
         return updatedString;
     }

    const url = cleanUrlString("/"+props.recordData[4]+"/"+props.recordData[1]+" "+props.recordData[2])

    //const artist = recordData[1].replace(' ','-');
   
    
    return (
        <Link  className="recordCard" key={props.recordData[0]}
        
        onClick={ (event) => props.disableClick ? event.preventDefault() : null }
        style={{textDecoration: 'none', color: 'inherit', cursor: props.disableClick ? "default" : "pointer"}}
            to={url} 
            state={{
                data: {
                    id:props.recordData[0],
                    artist:props.recordData[1],
                    recordName:props.recordData[2],
                    review:props.recordData[5], 
                    releaseYear: props.recordData[6],
                    image: props.recordData[3],
                    genre: props.recordData[4],
                    playTime: props.recordData[7],
                    reviewedAt: props.recordData[8],
                    reviewedBy: props.recordData[9],
                    subGenre: props.recordData[11],
                    
                }
            }}>
            <div style={{
                backgroundImage: `url(${"/upload/"+ props.recordData[3]})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width:props.width,
                height:props.height,
                display:'flex',
                flexDirection:'column',
                
                justifyContent:props.recordData[4].toLowerCase() == "annat" || props.showLabels ? 'space-between' : "flex-end"
                

            }}>
                {(props.recordData[4].toLowerCase() == "annat" || props.showLabels) &&
                    <div className="recordCardLabel">
                        <p style={{fontSize: props.labelSize}}>
                            {props.recordData[4].toLowerCase() == "annat" ? 
                                props.recordData[11] 
                                : props.recordData[4].charAt(0).toUpperCase() + //Capitalize first letter
                                props.recordData[4].slice(1)}
                        </p>
                    </div>
                }
                
                <div className="recordCardNameRecord">
                    <p style={{fontSize: fontSize}} className="recordCardName">{artistCardText}</p>
                    <p style={{fontSize: props.recordFontSize ? props.recordFontSize : "18px"}} className="recordCardRecord">{recordCardText}</p>
                </div>

                {
                    /*recordData.map((data) => {
                        return(
                            <div className="latestRecord">
                                <p>{data[1]}</p>
                            </div>
                        )
                    })*/
                }
                
            </div>
        </Link>
    )
}