import AboutSection from "../../components/aboutsection/AboutSection"
import Navbar from "../../components/Navbar/Navbar"
import "./about.css"

import testimg from "../../assets/images/a.jpg"
import { useContext, useEffect } from "react"
import { AuthContext } from "../../context/authContext"
import { Link, useNavigate } from "react-router-dom"
import { convertFromRaw, Editor, EditorState } from "draft-js"
import { useQuery } from "@tanstack/react-query"
import { makeRequest } from "../../axios"
import Footer from "../../components/footer/Footer"
import RecordCard from "../../components/RecordCard/RecordCard"
import MediaQuery from "react-responsive"
import RecordCarousel from "../../components/carousel/RecordCarousel"

import EditIcon from "../../assets/icons/edit-pencil-black.svg";
import NavigationLeftGold from "../../assets/icons/nav-arrow-left-gold.svg";

export default function About() {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
    }, [])

    const {user} = useContext(AuthContext)
    const navigate = useNavigate()

    
    const profilePicQuery = useQuery(["profilePics"], () =>
        makeRequest.get("https://kavemark.se/api/getprofilepics", {mode: "cors"}).then((res) => {
            return res.data;
        })
     );

     let leftPic, rightPic = ""
     profilePicQuery.data ? [leftPic, rightPic] = profilePicQuery.data : [leftPic, rightPic] = ""

    const infoQuery = useQuery(["info"], () =>
    makeRequest.get("https://kavemark.se/api/info", {mode: "cors"}).then((res) => {
        return res.data;
    })
    );

    //const data = "hejsan"
    const data = infoQuery.data?.map((data) => data[1])
    //const data = infoQuery.data[0]?


    let editorState = EditorState.createEmpty()

    if(data){
        const contentState = convertFromRaw(JSON.parse(data))
        editorState = EditorState.createWithContent(contentState)
    }

    const favoritesQuery = useQuery(["favoriteRecords"], () =>
        makeRequest.get("https://kavemark.se/api/favorites", {mode: "cors"}).then((res) => {
            return res.data;
        })
     );
     const recordData = favoritesQuery.data ? favoritesQuery.data : null

     const compareFunction = (a, b) => {
        const numA = a[10]; // Get the numerical element from the first inner array
        const numB = b[10]; // Get the numerical element from the second inner array
        
        // Compare the numerical elements
        if (numA < numB) {
            return -1; // If numA is less than numB, place 'a' before 'b'
        } else if (numA > numB) {
            return 1; // If numA is greater than numB, place 'b' before 'a'
        } else {
            return 0; // If numA is equal to numB, maintain their relative order
        }
     }

     useEffect(() => {
        if(recordData){
            recordData.sort(compareFunction)
        }
            
     }, [recordData])
   

    return (
        <div className="about">
            <Navbar />
            {/*<AboutSection />*/}

            <div className="aboutWrapper">
                <MediaQuery maxWidth={600}>
                    <p className="aboutHeader">Om oss</p>

                    <div className="aboutContent">
                        <div className="aboutDesc">
                            <Editor editorState={editorState} readOnly={true} textAlignment="center" />

                        </div>

                        <div className="aboutProfiles">
                            <div className="aboutProfile" >
                                <img className="aboutProfilePic" src={"/profile_pictures/"+leftPic}/>
                                <p className="aboutProfileName">Claes Runbalk</p>
                                <p className="aboutProfileEmail">crunbalk@gmail.com</p>
                            </div>
                            <div className="aboutProfile" style={{}} >
                                <img className="aboutProfilePic" src={"/profile_pictures/"+rightPic}/>
                                <p className="aboutProfileName">Hans Runbalk</p>
                                <p className="aboutProfileEmail">hansrunbalk@gmail.com</p>
                            </div>
                            
                            
                        </div>
                        <RecordCarousel />
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={601} maxWidth={1000}>
                    <p className="aboutHeader">Om oss</p>

                    <div className="aboutContent">
                        <div className="aboutDesc">
                            <Editor editorState={editorState} readOnly={true} />

                        </div>

                        <AboutSection detailed={false} />
                        <RecordCarousel />
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={1001} maxWidth={1599}>
                <div className="aboutContentHeader">
                        <div className="aboutHeaderLeft">
                            <img style={{width:"40px", cursor:"pointer"}} src={NavigationLeftGold} onClick={() =>navigate("/")} />
                        </div>

                        <div className="aboutHeaderMid">
                            <p className="aboutHeader">Om oss</p>
                        </div>
                       
                        <div className="aboutHeaderRight">
                            {user &&
                                <Link className="aboutEditLink" to={"/about/edit"}
                                    state={{
                                        data:{
                                            desc:data,
                                            leftPic:leftPic,
                                            rightPic:rightPic,
                                            favoriteRecords:recordData
                                        }
                                    }}
                                >
                                    <img src={EditIcon} />
                                    <p style={{marginLeft:"8px"}} >Redigera</p>
                                </Link>
                            }
                        </div>
                        
                        
                        
                    </div>
                    <div className="aboutContent">
                        <div className="aboutDesc">
                            <Editor editorState={editorState} readOnly={true} />

                        </div>

                        <div className="aboutProfiles">
                            <div className="aboutProfile" >
                                <img className="aboutProfilePic" src={"/profile_pictures/"+leftPic}/>
                                <p className="aboutProfileName">Claes Runbalk</p>
                                <p className="aboutProfileEmail">crunbalk@gmail.com</p>
                            </div>
                            <div className="aboutProfile" style={{marginTop:"50px"}} >
                                <img className="aboutProfilePic" src={"/profile_pictures/"+rightPic}/>
                                <p className="aboutProfileName">Hans Runbalk</p>
                                <p className="aboutProfileEmail">hansrunbalk@gmail.com</p>
                            </div>
                            
                            
                        </div>
                    </div>
                </MediaQuery>

                <MediaQuery minWidth={1600}>
                    <div className="aboutContentHeader">
                        <div className="aboutHeaderLeft">
                            <img style={{width:"40px", cursor:"pointer"}} src={NavigationLeftGold} onClick={() =>navigate("/")} />
                        </div>

                        <div className="aboutHeaderMid">
                            <p className="aboutHeader">Om oss</p>
                        </div>
                       
                        <div className="aboutHeaderRight">
                            {user &&
                                <Link className="aboutEditLink" to={"/about/edit"}
                                    state={{
                                        data:{
                                            desc:data,
                                            leftPic:leftPic,
                                            rightPic:rightPic,
                                            favoriteRecords:recordData
                                        }
                                    }}
                                >
                                    <img src={EditIcon} />
                                    <p style={{marginLeft:"8px"}} >Redigera</p>
                                </Link>
                            }
                        </div>
                        
                        
                        
                    </div>
                    <div className="aboutContent">
                        <div className="aboutDesc">
                            <Editor editorState={editorState} readOnly={true} />

                        </div>

                        <div className="aboutProfiles">
                            <div className="aboutProfile" style={{marginRight:"34px"}}>
                                <img className="aboutProfilePic" src={"/profile_pictures/"+leftPic}/>
                                <p className="aboutProfileName">Claes Runbalk</p>
                                <p className="aboutProfileEmail">crunbalk@gmail.com</p>
                            </div>
                            <div className="aboutProfile" style={{marginRight:"73px"}} >
                                <img className="aboutProfilePic" src={"/profile_pictures/"+rightPic}/>
                                <p className="aboutProfileName">Hans Runbalk</p>
                                <p className="aboutProfileEmail">hansrunbalk@gmail.com</p>
                            </div>
                            
                            
                        </div>
                    </div>

                    
                </MediaQuery>
            </div>
            <MediaQuery minWidth={1001} maxWidth={1599}>
                <div className="aboutFavoritesSection">
                    <p className="aboutFavoritesText">Favoriter</p>
                    <div className="aboutFavorites">
                        {recordData == null ? "Loading..."
                        : recordData.map(data =>{
                            return (
                                <div key={data[0]} className="aboutFavoriteCard">
                                    <RecordCard 
                                        width="154px"
                                        height="154px"
                                        genre={data[4]}
                                        recordData={data}
                                        fontSize="12px"
                                        smallFont="0.7vw"
                                        recordFontSize="9px"
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={1600}> 
                <div className="aboutFavoritesSection">
                        <p className="aboutFavoritesText">Favoriter</p>
                        <div className="aboutFavorites">
                            {recordData == null ? "Loading..."
                            : recordData.map(data =>{
                                return (
                                    <div key={data[0]} className="aboutFavoriteCard">
                                        <RecordCard 
                                            width="210px"
                                            height="210px"
                                            genre={data[4]}
                                            recordData={data}
                                            fontSize="14px"
                                            smallFont="0.7vw"
                                            recordFontSize="13px"
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
            </MediaQuery>               

            <Footer />
        </div>
    )
}