import "./editabout.css"
import { EditorState, convertFromRaw } from "draft-js"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import TextEditor from "../../components/texteditor/TextEditor"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { makeRequest } from "../../axios"
import { AuthContext } from "../../context/authContext"
import Navbar from "../../components/Navbar/Navbar"

import testimg from "../../assets/images/a.jpg"
import SearchBar from "../../components/SearchBar/SearchBar"
import { EditFavorites } from "../../components/EditFavorites/EditFavorites"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from 'react-dnd-html5-backend'

import NavigationLeftGold from "../../assets/icons/nav-arrow-left-gold.svg";


export default function EditAbout(){

    const {user} = useContext(AuthContext)
    const navigate = useNavigate()
    const location = useLocation()
    const data = location.state?.data
    
    console.log(user)

    const [desc, setDesc] = useState("");
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [leftImageChanged, setLeftImageChanged] = useState(false);
    const [rightImageChanged, setRightImageChanged] = useState(false);
    const [mouseOverLeftImage, setMouseOverLeftImage] = useState(false)
    const [mouseOverRightImage, setMouseOverRightImage] = useState(false)

    const [searchResult, setSearchResult] = useState(null)
    const [searchField, setSearchField] = useState(null)
    const [searchedFor, setSearchedFor] = useState("")
    const [favorites, setFavorites] = useState([])

    const acceptedDrops = searchResult?.map((res) => res[0])

    const favoritesCallback = (childFavorites) => {
        setFavorites(childFavorites)
        
    }

    const handleCallback = (childData) => {
        setDesc(JSON.stringify(childData))
    }

    const handleLeftImageChange = (e) => {
        setFile1(e.target.files[0])
        setLeftImageChanged(true)
    }

    const handleRightImageChange = (e) => {
        setFile2(e.target.files[0])
        setRightImageChanged(true)
    }

    const removeLeftImage = (e) => {
        setFile1(null)
        setLeftImageChanged(true)
    }

    const removeRightImage = (e) => {
        setFile2(null)
        setRightImageChanged(true)
    }

    useEffect(() => {
        setDesc(data.desc)
        setFile1(data.leftPic)
        setFile2(data.rightPic)
    }, [])

    

   

    const queryClient = useQueryClient();

    const mutation = useMutation(
        (newPost) => {
          return makeRequest.put("https://kavemark.se/api/info", newPost);
        },
        {
          onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries(["info"]);
          },
        }
    );


    const userMutation = useMutation(
        (newPost) => {
          return makeRequest.put("https://kavemark.se/api/updateuser", newPost);
        },
        {
          onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries(["users"]);
          },
        }
    ); 

    const favoritesMutation = useMutation(
        (newPost) => {
            return makeRequest.put("https://kavemark.se/api/favorites", newPost);
        },
        {
            onSuccess: () => {
              // Invalidate and refetch
              queryClient.invalidateQueries(["favoriteRecords"]);
            },
          }
    )

    const uploadProfilePic = async (file) => {
        try {
          const formData = new FormData();
          formData.append("file", file);
          const res = await makeRequest.post("https://kavemark.se/api/profileupload", formData);
          return res.data;
        } catch (err) {
          console.log(err);
        }
    };

    const handleClick = async (e) => {
        e.preventDefault();
        //e.stopPropagation();
        
        setFavorites(favorites.map((fav, index) => fav[10] = index+1))

       if(desc != data.desc){
        mutation.mutate({ aboutDesc:desc});
       }

        let imgUrl = "";
        if(user.username == "Claes" && file1 && leftImageChanged)
        {
            imgUrl = await uploadProfilePic(file1);
            userMutation.mutate({username:user.username, profilePic:imgUrl})
        }

        if(user.username == "Hans" && file2 && rightImageChanged)
        {
            imgUrl = await uploadProfilePic(file2);
            userMutation.mutate({username:user.username, profilePic:imgUrl})
        }
        if(favorites.length > 0){
            const newFavoriteIdsArr = favorites.map((fav) => fav[0])
            const newFavoritePosArr = favorites.map((fav) => fav[10])

            favoritesMutation.mutate({newFavoriteIds:newFavoriteIdsArr, newFavoritePos:newFavoritePosArr})
        }
        
        
        navigate(
            "/",
            {
                state: {
                   about: true
                }
            }
        )
        return;
        
      };

      if(searchField){
        console.log(searchResult)
        setSearchedFor(searchField)
        setSearchField(null)
      }

      let editorState = EditorState.createEmpty()
      if(data && data.desc != ""){       
          const contentState = convertFromRaw(JSON.parse(data.desc[0]))
          editorState = EditorState.createWithContent(contentState)
      }

    return(
        <div className="editAbout">
            <Navbar />
            <div className="editAboutWrapper">
                
                <div className="editAboutHeader">
                    <img src={NavigationLeftGold} onClick={() => navigate(-1)} />
                    <p>Om oss</p>
                </div>
                <div className="editAboutContent">
                    
                    <div className="editAboutDesc">
                        <TextEditor editorState={editorState} className="addFormReviewInput" parentCallback={handleCallback} />
                    </div>
                    <div className="editAboutRight">
                        <div className="editImageWrapper">
                            <div className="editImageLeft">
                                {console.log(file1)}
                                <input type="file" id="file1" style={{display:"none"}} onChange={handleLeftImageChange} name="image" />
                                {file1 && leftImageChanged ?
                                <img className={mouseOverLeftImage && user.username=="Claes" ? "editImageHover" : "editImage"}
                                    onMouseOver={()=>setMouseOverLeftImage(true)}
                                    onMouseOut={()=>setMouseOverLeftImage(false)}
                                src={URL.createObjectURL(file1)} />
                                : <img className={mouseOverLeftImage && user.username=="Claes" ? "editImageHover" : "editImage"}
                                onMouseOver={()=>setMouseOverLeftImage(true)}
                                onMouseOut={()=>setMouseOverLeftImage(false)}
                            src={"/profile_pictures/"+file1} />
                                }
                                {mouseOverLeftImage && user.username=="Claes" &&
                                    <label className ="editImageIcon" htmlFor="file1">
                                    <i className ="fa-solid fa-camera" 
                                        onMouseOver={()=>setMouseOverLeftImage(true)}
                                            onMouseOut={()=>setMouseOverLeftImage(false)}>
                                    </i>
                                    </label>
                                    
                                }
                                
                            </div>
                            <p className="aboutProfileName">Claes Runbalk</p>
                            <p className="aboutProfileEmail">crunbalk@gmail.com</p>
                        </div>

                        <div className="editImageWrapper" >
                            <div className="editImageRight" >
                            
                                {console.log(file2)}
                                <input type="file" id="file2" style={{display:"none"}} onChange={handleRightImageChange} name="image" />
                                {file2 && rightImageChanged ?
                                
                                <img className={mouseOverRightImage && user.username=="Hans" ? "editImageHover" : "editImage"}
                                    onMouseOver={()=>setMouseOverRightImage(true)}
                                    onMouseOut={()=>setMouseOverRightImage(false)}
                                src={URL.createObjectURL(file2)} />
                                : <img className={mouseOverRightImage && user.username=="Hans" ? "editImageHover" : "editImage"}
                                onMouseOver={()=>setMouseOverRightImage(true)}
                                onMouseOut={()=>setMouseOverRightImage(false)}
                            src={"/profile_pictures/"+file2} />
                                }
                                {mouseOverRightImage && user.username=="Hans" &&
                                    <label className ="editImageIcon" htmlFor="file2">
                                    <i className ="fa-solid fa-camera" 
                                        onMouseOver={()=>setMouseOverRightImage(true)}
                                            onMouseOut={()=>setMouseOverRightImage(false)}>
                                    </i>
                                    </label>
                                    
                                }
                            </div>
                            <p className="aboutProfileName">Hans Runbalk</p>
                            <p className="aboutProfileEmail">hansrunbalk@gmail.com</p>
                        </div>
                    </div>
                </div>

                
            </div>
            <div className="editAboutFavoritesSection">
                <p className="editAboutFavoritesText">Redigera favoriter</p>
                <div className="editAboutSearchBar">
                    <SearchBar className="editAboutSearchBar" setSearchResult={setSearchResult} setSearchField={setSearchField} />
                </div>
                <p className="editAboutSearchText">Du sökte på: {searchedFor}</p>
                
                <DndProvider backend={HTML5Backend}>
                    <EditFavorites favoritesCallback={favoritesCallback} acceptedDrops={acceptedDrops} searchResult={searchResult} favoriteRecords={data.favoriteRecords} />
                </DndProvider>

                <button className="addReviewButton" type={"button"} onClick={handleClick}>Publicera</button>
            </div>
                
                
                
        </div>
    )
}